import { post } from '@/utils/request';

const listReq = (cond) => post({
  url: '/store/goods.configuration.Warehouse/list',
  data: {
    ...cond,
  },
});

// 选择门店
const getMerchantListReq = () => post({
  url: '/store/common.select/getMerchantList',
});

// 门店&商家数据
const getMerchantListTwo = () => post({
  url: '/store/common.select/getMerchantListTwo',
})

// 新增or编辑门店
const saveReq = ({
  warehouse_name,
  warehouse_status,
  hierarchy_id,
  warehouse_id,
  sort,
}) => post({
  url: '/store/goods.configuration.Warehouse/save',
  data: {
    warehouse_name,
    warehouse_status,
    hierarchy_id,
    warehouse_id,
    sort,
  },
});

// 删除仓库
const deleWarehouseReq = (warehouse_id) => post({
  url: '/store/goods.configuration.Warehouse/del',
  data: {
    warehouse_id,
  },
});

export {
  listReq,
  getMerchantListReq,
  saveReq,
  getMerchantListTwo,
  deleWarehouseReq,
};

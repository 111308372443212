<template>
  <div>
    <div style="width: 100%; display: flex" v-if="!isDetails">
      <div class="storeList" style="width: 11%">
        <div class="title">
          <div class="span"></div>
          门店
        </div>
        <div style="min-height: 500px; max-height: 700px; overflow-y: auto">
          <div
            class="store"
            v-for="(item, index) in storeList"
            v-bind:key="index"
            :class="hierarchy_id === item.hierarchy_id ? 'selectStore' : null"
            @click="handleStore(item)"
          >
            <div class="text">{{ item.hierarchy_name }}</div>
          </div>
        </div>
      </div>
      <div style="width: 88%">
        <div class="tableTop">
          <div class="title" style="float: left">
            <div class="span"></div>
            积分获取规则
          </div>
          <el-button
            type="primary"
            @click="handleAdd()"
            style="float: right; margin-right: 15px"
            :disabled="isShareAble"
            >新增积分获取规则</el-button
          >
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="730"
            @row-dblclick="handleDalclick"
          >
            <el-table-column
              align="center"
              prop="rule_name"
              label="规则名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="goods_type_name"
              label="品类"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="integral_type_text"
              label="获取积分规则"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="integral_proportion"
              label="每一元获得的积分值"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.integral_proportion }}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="sales_return_integral_text"
              label="退货，积分是否退还"
            ></el-table-column> -->
            <el-table-column
              align="center"
              prop="status_text"
              label="是否启用"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="sort"
              label="优先级"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="add_user_realname"
              label="添加人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="create_time"
              label="添加时间"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="operation"
              label="操作"
              width="180"
            >
              <template slot-scope="scope">
                <div class="options">
                  <el-select
                    v-model="scope.row.select"
                    placeholder="请选择"
                    @change="handleDetails($event, scope.row)"
                  >
                    <el-option
                      label="详情"
                      :value="1"
                      :disabled="isShareAble"
                    ></el-option>
                    <el-option
                      label="编辑"
                      :value="2"
                      :disabled="isShareAble"
                    ></el-option>
                    <el-option
                      label="删除"
                      :value="3"
                      :disabled="isShareAble"
                    ></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagina">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-sizes="[15, 30, 40, 50]"
            :page-size="15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="visiable"
      :title="type === 'add' ? '新增积分规则' : '编辑积分规则'"
      :destroy-on-close="true"
      :before-close="cancelDia"
    >
      <el-form :model="integralForm" :rules="rules" ref="form">
        <div class="form">
          <el-form-item
            label="规则名称"
            :label-width="formLabelWidth"
            prop="rule_name"
          >
            <el-input
              v-model="integralForm.rule_name"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="选择品类"
            :label-width="formLabelWidth"
            prop="goods_type_id"
          >
            <el-select
              v-model="integralForm.goods_type_id"
              placeholder="请选择品类"
              style="width: 60%"
              multiple
              collapse-tags
            >
              <template v-for="item in attrList">
                <el-option
                  :label="item.goods_type_name"
                  :value="item.goods_type_id"
                  :key="item.goods_type_id"
                >
                  <span :style="{ marginLeft: item.span * 8 + 'px' }">{{
                    item.goods_type_name
                  }}</span>
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            label="获取积分方式"
            :label-width="formLabelWidth"
            prop="integral_type"
          >
            <el-select
              v-model="integralForm.integral_type"
              placeholder="请选择获取积分方式"
              style="width: 60%"
            >
              <el-option label="按标签价" :value="10"></el-option>
              <el-option label="按货品实付金额" :value="20"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="每一元可获得"
            :label-width="formLabelWidth"
            prop="integral_proportion"
          >
            <div style="display: flex">
              <el-input
                v-model="integralForm.integral_proportion"
                autocomplete="off"
                style="width: 60%"
                placeholder="请输入积分值"
              ></el-input>
              <span style="margin-left: 5px; color: #909399"
                >积分（可输入四位小数）</span
              >
            </div>
          </el-form-item>
          <el-form-item label="优先级" :label-width="formLabelWidth">
            <div style="display: flex">
              <el-input
                v-enterNumber
                v-model="integralForm.sort"
                autocomplete="off"
                style="width: 60%"
                placeholder="请输入优先级"
              ></el-input>
              <span style="margin-left: 5px; color: #909399"
                >（数字越大优先级越高）</span
              >
            </div>
          </el-form-item>
          <!-- <el-form-item
            label="退货, 积分是否退还"
            :label-width="formLabelWidth"
          >
            <el-radio-group v-model="integralForm.sales_return_integral">
              <el-radio :label="20">是</el-radio>
              <el-radio :label="10">否</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="是否启用" :label-width="formLabelWidth">
            <el-radio-group v-model="integralForm.status">
              <el-radio :label="20">启用</el-radio>
              <el-radio :label="10">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div style="text-align: right">
          <el-button type="primary" @click="submit('form')" :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelDia">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <Details
      v-if="isDetails"
      :client_get_inegral_rule_id="client_get_inegral_rule_id"
      @hide="getList"
    />
  </div>
</template>

<script>
import Details from "./details";
import storage from "good-storage";
import { getMerchantListTwo } from "@/api/goods/goodsStore/list";
import {
  integGetList,
  integGetAdd,
  integGetUpdate,
  integGetDel,
} from "@/api/member/integralSetting/index";
import { getFind } from "@/api/system/integralRule";
import { getListReq } from "@/api/goods/goodsConfig/classify";

export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^\d+(.\d{1,4})?$/.test(value)) {
        callback(new Error("请输入正整数，最多四位小数"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      storeList: [],
      tableData: [],
      visiable: false,
      isDetails: false,
      share_type: null,
      isShareAble: false,
      type: "add",
      client_get_inegral_rule_id: 0,
      formLabelWidth: "150px",
      integralForm: {
        goods_type_id: [],
        status: 20,
      },
      attrList: [],
      userInfo: {},
      rules: {
        rule_name: [
          { required: true, message: "请填写规则名称", trigger: "blur" },
        ],
        goods_type_id: [
          {
            required: true,
            message: "请选择品类",
            trigger: "change",
          },
        ],
        integral_type: [
          {
            required: true,
            message: "请选择获取积分方式",
            trigger: "change",
          },
        ],
        integral_proportion: [
          { required: true, message: "请填写积分值", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
      page: 1,
      limit: 15,
      total: 0,
      type_id: null,
    };
  },
  components: {
    Details,
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantListReq();
  },
  methods: {
    /*
     *  积分规则
     */
    getRule() {
      getFind().then((res) => {
        if (res.code === 1) {
          if (res.data !== null) {
            this.share_type = res.data.share_type;
          }
        }
        this.getList();
      });
    },
    /*
     *  选择门店
     */
    handleStore(item) {
      this.type_id = item.type_id;
      this.hierarchy_id = item.hierarchy_id;
      this.getList();
    },
    /*
     *   获取品类下拉数据
     */
    getGoodsTypeList() {
      getListReq().then((res) => {
        if (res.code === 1) {
          this.attrList = this.handleArr(res.data);
        }
      });
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    /*
     *  获取积分规则数据
     */
    getList() {
      this.isDetails = false;
      const data = {
        hierarchy_id: this.hierarchy_id,
        page: this.page,
        limit: this.limit,
      };
      if (this.share_type === 1) {
        if (this.userInfo.hierarchy_type_id === 30 && this.type_id === 30) {
          this.isShareAble = false;
        } else {
          this.isShareAble = true;
        }
      } else if (this.share_type === 2) {
        if (this.userInfo.hierarchy_type_id === 30 && this.type_id === 30) {
          this.isShareAble = false;
        } else if (
          this.userInfo.hierarchy_type_id === 40 &&
          this.type_id === 30
        ) {
          this.isShareAble = true;
        } else if (
          this.userInfo.hierarchy_type_id === 40 &&
          this.type_id === 40
        ) {
          this.isShareAble = false;
        }
      }
      integGetList(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    /*
     *  新增获取规则数据
     */
    handleAdd() {
      this.type = "add";
      this.visiable = true;
      this.getGoodsTypeList();
    },
    /*
     *  关闭弹窗
     */
    cancelDia() {
      this.visiable = false;
      this.integralForm = {
        goods_type_id: [],
        status: 20,
      };
      this.getList();
    },
    /*
     *  获取门店数据
     */
    getMerchantListReq() {
      getMerchantListTwo({ status: 10 }).then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
          this.hierarchy_id = res.data[0].hierarchy_id;
          this.type_id = res.data[0].type_id;
          this.getRule();
        }
      });
    },
    /*
     *  删除
     */
    handleDelete() {
      this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          integGetDel({
            client_get_inegral_rule_id: this.client_get_inegral_rule_id,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getList();
        });
    },
    handleDalclick(row) {
      this.client_get_inegral_rule_id = row.client_get_inegral_rule_id;
      this.isDetails = true;
    },
    /*
     *  操作
     */
    handleDetails(event, row) {
      this.client_get_inegral_rule_id = row.client_get_inegral_rule_id;
      if (event === 1) {
        this.isDetails = true;
      } else if (event === 2) {
        this.visiable = true;
        this.type = "edit";
        row.goods_type_id = row.goods_type_id.split(",");
        row.goods_type_id = row.goods_type_id.map((item) => {
          item = Number(item);
          return item;
        });
        this.integralForm = row;
        this.getGoodsTypeList();
      } else if (event === 3) {
        this.handleDelete();
      }
    },
    /*
     *  保存数据
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          const data = this.integralForm;
          data.goods_type_id = data.goods_type_id.join(",");
          if (this.type === "add") {
            data.hierarchy_id = this.hierarchy_id;
            integGetAdd(data)
              .then((res) => {
                if (res.code === 1) {
                  this.loading = false;
                  this.$message({
                    message: "保存成功！",
                    type: "success",
                  });
                  this.cancelDia();
                } else {
                  this.loading = false;
                }
              })
              .catch((res) => {
                console.log(res);
                this.loading = false;
              });
          } else {
            integGetUpdate(data)
              .then((res) => {
                if (res.code === 1) {
                  this.loading = false;
                  this.$message({
                    message: "更新成功！",
                    type: "success",
                  });
                  this.cancelDia();
                } else {
                  this.loading = false;
                }
              })
              .catch((res) => {
                console.log(res);
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .title {
  position: relative;
  margin-bottom: 14px;
  margin-left: 20px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 30px;

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 15px;
    background: #2791ff;
    left: -8px;
    top: 8px;
    border-radius: 99px;
  }
}
.tableTop {
  height: 45px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 6px 6px 0px 0px;
}
/deep/ .storeList {
  padding-top: 22px;
  margin-right: 10px;
  background: #ffffff;
  height: 90vh;
  border: 1px solid #ddd;
  border-radius: 6px;

  .store {
    padding-left: 17px;
    width: 100%;
    background: white;
    padding: 10px 0;
    font-size: 14px;

    .text {
      margin-left: 15px;
    }
  }
  .store:hover {
    cursor: pointer;
  }

  .selectStore {
    background-color: #ecf5ff;
    .text {
      margin-left: 14px;
    }
  }
}
</style>
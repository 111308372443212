<template>
  <div>
    <div class="content">
      <div class="newly">
        <el-page-header @back="goBack" content="积分获取详情"></el-page-header>
      </div>
    </div>
    <div class="top">
      <div class="item" v-for="item in arr" :key="item.id">
        <div class="item-left">{{ item.name }}</div>
        <div class="item-right">{{ item.value }}</div>
      </div>
    </div>
    <div class="newForm" style="padding-top:15px">
      <div class="title">
        操作日志
      </div>
      <el-table :data="logData" stripe>
        <el-table-column
          label="操作时间"
          prop="time"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作内容"
          prop="about"
          align="center"
        ></el-table-column>
        <el-table-column label="操作人" prop="name" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { integGetList } from "@/api/member/integralSetting/index";
export default {
  data() {
    return {
      arr: [
        {
          name: "规则名称",
          value: "",
          prop: "rule_name",
        },
        {
          name: "所选品类",
          value: "",
          prop: "goods_type_name",
        },
        {
          name: "获取积分规则",
          value: "",
          prop: "integral_type_text",
        },
        {
          name: "比例值",
          value: "",
          prop: "integral_proportion",
        },
        // {
        //   name: "退货，积分是否退还",
        //   value: "",
        //   prop: "sales_return_integral_text",
        // },
        {
          name: "是否启用",
          value: "",
          prop: "status_text",
        },

        {
          name: "优先级",
          value: "",
          prop: "sort",
        },
        {
          name: "添加人",
          value: "",
          prop: "add_user_realname",
        },
        {
          name: "添加时间",
          value: "",
          prop: "create_time",
        },
      ],
      logData: [],
    };
  },
  props: {
    client_get_inegral_rule_id: Number,
  },
  created() {
    this.getInfo();
  },
  methods: {
    /*
     * 详情数据
     */
    getInfo() {
      const data = {
        client_get_inegral_rule_id: this.client_get_inegral_rule_id,
      };
      integGetList(data).then((res) => {
        if (res.code === 1) {
          this.arr.forEach((item) => {
            item.value = res.data.list[0][item.prop]
              ? res.data.list[0][item.prop]
              : "--";
          });
        }
      });
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .newly {
  padding: 20px;
}
/deep/ .top {
  display: flex;
  padding: 30px 50px 10px 50px;
  background: white;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-top: 20px;
  .item {
    display: flex;
    margin-bottom: 20px;
    width: 33%;
    &-left {
      width: 130px;
      color: #909399;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791ff;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    }
    &-right {
      color: #565656;
      font-size: 16px;
      width: 300px;
    }
  }
}
</style>
import { post } from '@/utils/request';

// 积分规则设置-列表（一条）
const getFind = (data) => post({
    url: '/store/customer.SetIntegerRule/getFind',
    data: data
});

// 积分规则设置-条件
const createRule = (data) => post({
    url: '/store/customer.SetIntegerRule/create',
    data: data
})

// 积分规则设置-修改
const updateRule = (data) => post({
    url: '/store/customer.SetIntegerRule/update',
    data: data
})
export {
    getFind,
    createRule,
    updateRule
}